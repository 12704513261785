/* eslint-disable import/no-unresolved */
import React from 'react'

import { Blog } from 'components/elements'

const BlogOverview = ({ posts }) => (
  <div className="row">
    {posts.map(({ node }) => (
      <div key={node.wordpress_id} className="col-sm-6 col-lg-4 d-flex justify-content-center mb-4 pt-2">
        <Blog fields={node} />
      </div>
      ))}
  </div>
)

export default BlogOverview