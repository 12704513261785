/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

// Parse
import parse from 'html-react-parser'
import { renderToString } from 'react-dom/server'

// Elements
import { ParseContent, Image } from 'components/shared'

// Animation
import Lottie from 'react-lottie'
import * as animationData from 'src/animations/circle.json'

// Media
import ShineImg from 'img/shine.png'

const Content = styled.div`
  @media (min-width: 992px) {
    min-height: 750px;
  }

  & h1 {
    @media (max-width: 1299px) {
      font-size: ${(props) => props.theme.font.size.xl};
    }
  }

  & h2 {
    font-size: ${(props) => props.theme.font.size.xm};
  }
`

const ShineImage = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

const ContentRow = styled.div`
  z-index: 2;
`

const Animation = styled.div`
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 100%;

  @media (min-width: 1650px) {
    width: 1800px;
  }

  @media (max-width: 1649px) {
    width: 1700px;
  }

  @media (max-width: 1499px) {
    width: 1600px;
  }

  @media (max-width: 1299px) {
    width: 120vw;
  }

  @media (max-width: 991px) {
    margin-top: -150px;
    height: 550px;
    width: 140vw;
    margin-left: -20vw;
  }

  @media (max-width: 576px) {
    margin-top: -100px;
    margin-left: -20vw;
    height: 450px;
    width: 120vw;
  }
`

const HeroHome = ({ fields }) => {
  const [leadInfo, setLeadInfo] = React.useState(null)

  React.useEffect(() => {
    console.log('TEST. useEffect')

    ;(async () => {
      console.log('TEST. before try')

      try {
        const request = await fetch('https://api.leadinfo.com/v1/identify', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        })

        console.log('TEST. request', request)

        const response = await request.json()

        console.log('TEST. response', request)
        
        if(response) {
          setLeadInfo(response)
        }
      } catch (error) {
        console.error(error)

        console.log('TEST. error', error)
      }
    })()
  }, [])

  console.log('TEST. leadInfo', leadInfo)

  return (
    <div className="position-relative">
      <ShineImage>
        <Image src={ShineImg} alt="UBX" style={{ width: '100%' }} />
      </ShineImage>
      <Content className="container position-relative">
        <ContentRow className="row position-relative pt-5 pt-lg-0">
          <Content className="col-lg-7 d-flex align-items-center pb-5 pb-xl-0 pr-lg-5">
            <ParseContent
              className="mb-lg-5 pb-lg-5 pr-lg-5"
              content={renderToString(parse(fields.description, {
                // eslint-disable-next-line consistent-return
                replace: (domNode) => {
                  if (domNode.name === 'h1' && domNode.attribs.id === 'home-hello') {
                    return (
                      <h1>Hallo,</h1>
                    )
                  }
                }
              }))}
            />
          </Content>
        </ContentRow>
        <Animation className="position-absolute d-none d-lg-block">
          <LottieContent />
        </Animation>
      </Content>
      <Animation className="d-lg-none">
        <LottieContent />
      </Animation>
    </div>
  )
}

const LottieContent = () => (
  <Lottie
    options={{
      animationData: animationData.default,
      autoplay: true,
      loop: false,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }}
    height="100%"
    width="100%"
  />
)

export default HeroHome
