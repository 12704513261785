/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'

// Elements
import {
  ParseContent,
  ContainerRight,
  ImageBackground,
  Image,
} from 'components/shared'
import { CustomerCase } from 'components/elements'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import HeroHome from 'components/elements/HeroHome'

// Animation
import Lottie from 'react-lottie'

// Images
import BackImg from 'img/back-logo.png'

// Blog
import Blog from 'components/related/Blog'
import Blogik, { BlogConsumer } from 'components/shared/Blogik'

const UpgradeContent = styled(ParseContent)`
  margin-top: 130px;
`

const BigContainer = styled.div`
  margin-top: -50px;

  @media (min-width: 1200px) {
    max-width: 1300px;
  }
`

const UpgradeImage = styled(Image)`
  max-width: 1460px;
  
  & img {
    object-fit: cover !important;
  }
  
  @media (min-width: 992px) {
    height: auto;
  }

  @media (max-width: 991px) {
    height: 350px;
  }
`

const UpgradeRow = styled.div`
  & .col-6 {
    @media (max-width: 430px) {
      width: 100% !important;
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
`

const HomeTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => (
  <Layout activeMenu="Home">
    <SEO
      yoast={yoast}
      path={path}
      image={{
        url:
          acf.werkwijze.image.localFile &&
          acf.werkwijze.image.localFile.childImageSharp.openGraph.src,
      }}
    />

    <HeroHome fields={acf.banner} />

    <UBXImage>
      <Image src={BackImg} alt="UBX" style={{ width: '100%' }} />
    </UBXImage>

    <div className="mt-n5 pb-4 px-3 d-lg-block d-none">
      <UpgradeImage alt="UBX" className="mx-auto" src={acf.upgrades.image} />
    </div>

    {/* UPGRADES */}
    <BigContainer className="container mb-5">
      <UpgradeContent content={acf.upgrades.description} />
      <UpgradeRow className="row">
        {acf.upgrades.slides.map((slide, index) => (
          <div
            key={slide.title}
            index={index}
            className="col-6 col-lg-4 col-xl-3 mt-5"
          >
            {slide.highlighted && <UpgradeHighlighted fields={slide} />}
            {!slide.highlighted && <Upgrade fields={slide} />}
          </div>
        ))}
      </UpgradeRow>
    </BigContainer>

    {/* FEATURES */}
    <div className="mt-5 pt-5">
      <div className="mt-5 pt-5">
        <Features fields={acf.werkwijze} />
      </div>
    </div>

    <div className="py-5" />

    {/* CUSTOMER CASE */}
    <div className="container">
      <CustomerCase fields={acf.klantcase} />
    </div>

    {/* BLOGS */}
    <Blogik
      settings={{
        limit: 3,
      }}
    >
      <BlogConsumer>
        {({ hasPosts, posts }) => (
          <div className="py-lg-5 py-3">
            {hasPosts && (
              <div className="container py-5">
                <Blog posts={posts} />
              </div>
            )}
          </div>
        )}
      </BlogConsumer>
    </Blogik>

    <div className="py-5" />
  </Layout>
)

export default HomeTemplate

const UpgradeTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  min-height: 300px;
`

const UpgradeTr = styled.tr`
  min-height: 300px;
`

const UpgradeTd = styled.td`
  width: 45%;
  min-height: 300px;
  border-left: 3px solid ${(props) => props.theme.color.face.contrast};
  border-bottom: 3px solid ${(props) => props.theme.color.face.contrast};
  padding: 10px 10px 30px 20px;

  @media screen and (max-width: 768px) {
    padding: 0;
    text-align: center;
  }

  p {
    line-height: 24px;
    padding-right: 20px;

    @media (min-width: 768px) {
      min-height: 120px;
    }
  }
`

const InnerTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  height: 58px;
`
const InnerTr = styled.tr`
  height: 58px;
`

const InnerTd = styled.td`
  height: 100px;

  @media (min-width: 768px) {
    width: 25%;
  }

  @media (max-width: 767px) {
    width: 100%;
    display: block;
  }

  @media (max-width: 575px) {
    display: block;
    width: 100%;
    height: 125px;

    & a {
      height: 150px;
      width: 100px;
      display: block;
      margin: 0 auto;
    }
  }
`

const InnerTd2 = styled.td`
  @media (min-width: 768px) {
    width: 75%;
  }

  @media (max-width: 767px) {
    width: 100%;
    display: block;
  }

  @media (min-width: 576px) {
    height: 58px;
  }

  @media (max-width: 575px) {
    display: block;
    width: 100%;
  }
`

const UpgradeTitle = styled.span`
  color: #fff;
  @media screen and (max-width: 768px) {
    font-size: ${(props) => props.theme.font.size.m};
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

const UpgradeSubTitle = styled.span`
  color: #4bfbe7;

  @media screen and (max-width: 768px) {
    font-size: ${(props) => props.theme.font.size.m};
  }
`

const StyledLink = styled(Link)`
  font-size: 0;
`

const StyledLottie = styled(Lottie)`
  width: 59px;
  height: 59px;
`

const UpgradeTdContent = styled(ParseContent)`
  @media (max-width: 768px) {
    height: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
`

const Upgrade = ({ fields }) => (
  <>
    <UpgradeTable>
      <tbody>
        <UpgradeTr>
          <UpgradeTd>
            <InnerTable>
              <tbody>
                <InnerTr>
                  <InnerTd>
                    <StyledLink to={fields.link.url}>
                      {/* <StyledImage src={fields.icon} /> */}
                      <StyledLottie
                        options={{
                          animationData: JSON.parse(fields.animation),
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height="75%"
                        width="100%"
                      />
                      {fields.link.title}
                    </StyledLink>
                  </InnerTd>
                  <InnerTd2 className="pl-md-0 px-lg-2">
                    <h5 className="pl-sm-2 text-left px-3 px-sm-0">
                      <Link to={fields.link.url}>
                        <strong>
                          <UpgradeTitle>{fields.title}</UpgradeTitle>
                        </strong>
                      </Link>
                    </h5>
                    <h5 className="pl-sm-2 text-left px-3 px-sm-0">
                      <strong>
                        <UpgradeSubTitle>{fields.subtitle}</UpgradeSubTitle>
                      </strong>
                    </h5>
                  </InnerTd2>
                </InnerTr>
              </tbody>
            </InnerTable>
            &nbsp;
            <UpgradeTdContent
              className="text-left px-md-0 px-3 mt-md-0 mt-lg-3 mb-md-0 mb-3"
              content={fields.description}
            />
          </UpgradeTd>
        </UpgradeTr>
      </tbody>
    </UpgradeTable>
  </>
)

const StyledUpgradeHighlighted = styled.div`
  background-color: ${(props) => props.theme.color.face.contrast};
  color: ${(props) => props.theme.color.text.light};
  border-top-right-radius: 100px;

  button {
    font-size: ${(props) => props.theme.font.size.m};
    line-height: ${(props) => props.theme.font.size.m};
    color: ${(props) => props.theme.color.text.main};
    border: 2px solid ${(props) => props.theme.color.text.main};
    height: 50px;
    border-radius: 25px;
    padding-left: 40px;
    padding-right: 40px;
    transition: all 300ms ease;
    &:hover {
      background: ${(props) => props.theme.color.text.main};
      color: ${(props) => props.theme.color.text.light};
    }
  }
`

const UpgradeHighlightedContent = styled(ParseContent)`
  & h4 {
    font-size: ${(props) => props.theme.font.size.l};
  }
`

const UpgradeHighlightedButton = styled(Link)`
  font-size: ${(props) => props.theme.font.size.m};
  line-height: ${(props) => props.theme.font.size.m};
  color: ${(props) => props.theme.color.text.main};
  border: 2px solid ${(props) => props.theme.color.text.main};
  height: 50px;
  line-height: 46px;
  border-radius: 25px;
  padding-left: 30px;
  padding-right: 30px;
  transition: all 300ms ease;
  display: inline-block;

  &:hover {
    background: ${(props) => props.theme.color.text.main};
    color: ${(props) => props.theme.color.text.light};
    text-decoration: none;
  }
`

const UpgradeHighlighted = ({ fields }) => (
  <StyledUpgradeHighlighted className="h-100 d-flex justify-content-center align-items-center py-md-0 py-5">
    <div className="text-center">
      <UpgradeHighlightedContent content={fields.description} />
      <a className="my-auto" href="javascript:void(Tawk_API.toggle())">
        <button type="button" className="mt-4">{fields.link.title}</button>
      </a>
    </div>
  </StyledUpgradeHighlighted>
)

const UBXImage = styled.div`
  user-select: none;
  width: 80%;
  background-size: contain !important;
  background-position-x: 0 !important;

  @media screen and (min-width: 992px) {
    margin-top: -200px;
  }

  @media screen and (max-width: 991px) {
    margin-top: -100px;
  }

  @media screen and (max-width: 768px) {
    margin-top: -130px;
    width: 70%;
  }

  @media screen and (max-width: 575px) {
    margin-top: -100px;
  }

  @media screen and (max-width: 425px) {
    margin-top: -100px;
  }

  @media screen and (max-width: 375px) {
    margin-top: -100px;
  }

  & .gatsby-image-wrapper {
    max-height: 250px;
    overflow: initial !important;

    & img {
      max-height: 350px;
      object-fit: contain;
    }
  }
`

const FeaturesContainer = styled.div`
  position: relative;
  margin-top: -40px;
`

const FeatureImageWrapper = styled.div`
  @media (max-width: 991px) {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
`

const FeatureImageOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
`

const FeatureContentWrapper = styled.div`
  position: relative;
  z-index: 2;
`

const Features = ({ fields }) => (
  <FeaturesContainer className="d-flex flex-wrap position-relative">
    <FeatureImageWrapper className="w-100 w-lg-50">
      <FeatureImage
        src={fields.image}
        style={{ width: '100%', height: '100%', minHeight: 500 }}
      />
      <FeatureImageOverlay className="d-lg-none" />
    </FeatureImageWrapper>
    <FeatureContentWrapper className="w-100 w-lg-50 px-lg-0 px-3 py-5">
      <FeatureContent>
        <ParseContent content={fields.description} />
      </FeatureContent>
    </FeatureContentWrapper>
  </FeaturesContainer>
)

const FeatureImage = styled(ImageBackground)`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;

  @media (min-width: 992px) {
    min-height: 640px;
  }
`

const FeatureContent = styled(ContainerRight)`
  color: ${(props) => props.theme.color.text.light};

  & h2,
  & h3,
  & h4 & h5 {
    font-family: ${(props) => props.theme.font.family.main};

    @media (min-width: 992px) {
      font-size: ${(props) => props.theme.font.size.xl};
    }

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.l};
    }
  }

  & p {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size.sm};
    line-height: ${(props) => props.theme.font.size.l};

    & strong > span {
      margin: 0 auto;
      display: block;
    }
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...homePageFragment
    }
  }
`
